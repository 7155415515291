.divider {
  height: 1px;
  background: #E5E5E5;
  margin: 25px 0 19px 0;
}

.title {
  font-size: 36px;
  font-weight: 600;
  height: 52px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 20px;
  // justify-content: space-between;
}