.header {
  height: 64px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  z-index: 100;
  position: relative;
  
  a {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    padding: 0 16px 0 12px;
    height: 36px;
    line-height: 16px;
    color: #FFF;
    background: rgba(255,255,255,0.3);
    font-weight: 700;
    border-radius: 5px;
    transition: all 0.2s ease-out;
    
    img {
      margin-right: 8px;
    }
    
    &:hover {
      transform: scale(1.05);
    }
  }
}

.footer {
  position: fixed;
  display: flex;
  color: rgba(255,255,255,0.6);
  background: #910c2e;
  // backdrop-filter: blur(10px);
  font-size: 14px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 42px;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  display: flex;
  padding: 20px 0 43px 0;
  // this is a hack to center the content
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (max-width: 470px) {
  .headerBackButton {
    opacity: 0;
    pointer-events: none;
  }
}