.button {
  width: 100%;
  margin-top: 30px;
  font-family: Montserrat, sans-serif;
  height: 52px;
  background: #343434;
  color: #FFF;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  border-radius: 10px;
  transition: all 0.15s ease-out;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  letter-spacing: 0;
  line-height: 52px;
  &:disabled {
    opacity: 0.5;
    color: #FFF;
  }
  &:hover {
    background: lighten(#343434, 10%);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
  }
  
  &ProgressWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    background: #3F3E3E;
    transition: opacity 0.2s ease-out;
    pointer-events: none;
  }
}