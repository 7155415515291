.defaultFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form {
  &BottomBar {
    position: fixed;
    left: 290px;
    bottom: 0;
    background: #FFF;
    width: calc(100% - 290px);
    height: 82px;
    padding: 15px 52px;
    z-index: 20;
  }
  
  &BottomBarContent {
    max-width: 550px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.underlinedButton {
  font-size: 18px;
  text-decoration: underline;
  text-transform: none;
  font-family: 'Montserrat';
  color: #343434;
  margin-right: 30px;
  font-weight: bold;
  letter-spacing: 0;
}

.buttonSm {
  font-family: Montserrat, sans-serif;
  height: 32px;
  color: #343434;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  border-radius: 10px;
  transition: all 0.15s ease-out;
  padding: 0 10px 0 7px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-decoration: none;
  background: #CCC;
  span {
    margin-left: 8px;
  }
  &:hover {
    background: lighten(#c9c9c9, 10%);
  }
  &.add {
    color: #FFF;
    background: #008B5D;
    &:hover {
      background: darken(#008B5D, 10%);
    }
  }
  
  &.remove {
    color: #FFF;
    background: #bf4e44;
    padding: 0;
    width: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    &:hover {
      background: darken(#bf4e44, 10%);
    }
  }
}

.titleInput {
  border: none;
  background: rgba(0, 0, 0, 0.05);
  font-weight: bold;
  font-size: 19px;
  padding: 2px 10px;
  height: 32px;
  border-radius: 8px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-out;
  }
}

.deleteOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 10px;
  z-index: 10;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
  a {
    color: #fff;
  }
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  &:hover {
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-out;
  }
}