.title {
  font-size: 36px;
  font-weight: 600;
  height: 52px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 20px;
  // justify-content: space-between;
}

.button {
  font-family: Montserrat, sans-serif;
  height: 42px;
  background: #008B5D;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  border-radius: 10px;
  transition: all 0.15s ease-out;
  padding: 0 15px 0 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-decoration: none;
  span {
    margin-left: 8px;
  }
  &:hover {
    background: darken(#008B5D, 10%);
  }
}

.input {
  &MenuItem {
    font-family: 'Open Sans', sans-serif;
    height: 52px;
  }
}