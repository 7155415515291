.wrapper {
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #f8f8f8;
  transition: opacity 0.3s ease-out;
  overflow: hidden;
}

.gradient {
  width: 400px;
  height: 100%;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  transition: all 0.15s ease-out;
  background: linear-gradient(
    to right,
    #f8f8f8 0%,
    #eaeaea 50%,
    #f8f8f8 100%
  );
}

@keyframes placeholderShimmer {
  0% {
    transform: translateX(-400px);
  }
  100% {
    transform: translateX(1000px);
  }
}