@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:wght@400;500;600;700&display=swap');

$font-family: 'Montserrat', sans-serif;
$font-family-secondary: 'Open Sans', sans-serif;

html, body {
  font-family: $font-family-secondary;
  font-size: 16px;
  margin: 0;
}

div {
  box-sizing: border-box;
}