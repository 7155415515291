.wrapper {
  background: #FFF;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  position: relative;
}

.dynamicPlaceholder {
  background: #FFF;
  top: 8px;
  font-size: 14px;
}