.input {
  &MenuItem {
    font-family: 'Open Sans', sans-serif;
    height: 52px;
  }
}

.error {
  color: #E13434;
  font-size: 14px;
  margin-top: 2px;
}