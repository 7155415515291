.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  width: 100%;
}

.logo {
  width: 170px;
  margin-left: -40px;
  margin-bottom: 15px;
}

.card {
  width: 100%;
  max-width: 450px;
  background: #FFF;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &Title {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &Footer {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    padding-top: 15px;
    
    a {
      color: #E13434;
    }
  }
  &Link {
    color: #E13434;
    font-size: 14px;
    margin-top: 15px;
    display: block;
  }
}

.checkboxWrapper {
  display: flex;
  padding-top: 5px;
  align-items: flex-start;
}

@media (max-width: 470px) {
  .wrapper {
    padding: 0 10px;
  }
}

.logoWrapper {
  position: relative;
  span {
    position: absolute;
    background: #FFF;
    font-size: 11px;
    font-weight: 600;
    top: 5px;
    right: -39px;
    color: #3F3E3E;
    padding: 0px 4px 1px 5px;
    border-radius: 5px;
  }
}