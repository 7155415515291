.wrapper {
  background: #FFF;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  position: relative;
}

.textarea {
  width: 100%;
  border: 2px solid #E5E5E5;
  white-space: pre-wrap;
  font-size: 14px;
  background: white;
  border-radius: 8px;
  padding: 10px 15px;
  max-height: 300px;
  overflow: scroll;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}

.topRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bottomRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3px;
  font-size: 15px;
}

.bottomButton {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.priorityButtons {
  display: flex;
  margin-left: 10px;
}

.priorityButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 5px;
  background: #CCC;
  color: #343434;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  svg {
    margin-left: -2px;
  }
}

.disabled {
  opacity: 0.4;
}