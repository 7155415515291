.wrapper {
  width: 100%;
  font-family: Open Sans, sans-serif;
  height: 52px;
  border-radius: 10px;
  border: 2px solid #E5E5E5;
  font-size: 16px;
  padding: 0 10px;
  background: #FFF;
}

.error {
  color: #E13434;
  font-size: 14px;
  margin-top: 2px;
}

.group {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.placeholder {
  position: absolute;
  top: 14px;
  left: 10px;
  color: #999;
  padding: 0 2px;
  background: #FFF;
  transition: all 0.1s ease-out;
  pointer-events: none;
  &:first-letter {
    text-transform: uppercase;
  }
}

.active {
  top: -8px !important;
  left: 9px;
  font-size: 12px !important;
  background: linear-gradient(#f8f8f8 50%, #FFF 100%);
  color: #343434;
}