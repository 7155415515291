.wrapper {
  display: flex;
  height: 100vh;
  width: 100%;
}

.sidebar {
  min-width: 290px;
  width: 290px;
  background: #FFF;
  padding: 25px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.025);
  position: relative;
  overflow: scroll;
  &Footer {
    background: #FFF;
    position: absolute;
    padding: 0 20px 15px 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    &Row {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.profile {
  display: flex;
  align-items: center;
  
  &Avatar {
    min-width: 45px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    font-weight: 600;
    background: #CCC;
    color: #343434;
    text-align: center;
    line-height: 44px;
    text-transform: uppercase;
  }
  
  &Name {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 18px;
  }
}

.menu {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  &Divider {
    height: 1px;
    background: #E5E5E5;
    margin: 20px 0 14px 0;
  }

  &Item {
    height: 52px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-radius: 8px;
    column-gap: 8px;
    color: #343434;
    text-decoration: none;
    transition: all 0.1s ease-out;
    &Label {
      font-size: 14px;
      color: #999999;
      text-transform: uppercase;
      font-weight: 500;
      margin-bottom: 8px;
      font-family: Montserrat, sans-serif;
      // letter-spacing: 0.5px;
    }
    span {
      margin-left: 10px;
      margin-right: auto;
    }
    &.active {
      background: #F8F8F8;
    }
    &:hover {
      background: #F8F8F8;
    }
  }
}

.input {
  &MenuItem {
    font-family: 'Open Sans', sans-serif;
    height: 52px;
  }
}

.logo {
  position: relative;
  text-transform: none;
  span {
    position: absolute;
    background: #3F3E3E;
    font-size: 10px;
    font-weight: 600;
    top: -15px;
    right: -36px;
    color: #FFF;
    padding: 1px 5px 1px 5px;
    border-radius: 5px;
  }
}

.logout {
  font-size: 16px;
  text-transform: none;
  background: #F8F8F8;
  color: #343434;
  height: 45px;
  font-weight: 600;
  border-radius: 8px;
  padding: 0 10px;
  white-space: nowrap;
  &:hover {
    background: darken(#F8F8F8, 10%);
  }
}

.supportButton {
  height: 52px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-radius: 8px;
  column-gap: 8px;
  text-transform: none;
  font-size: 16px;
  font-family: Open Sans, sans-serif;
  color: #343434;
  background: #F8f8f8;
  span {
    margin-left: 10px;
    margin-right: auto;
  }
  &.active {
    background: #F8F8F8;
  }
  &:hover {
    background: #F8F8F8;
  }
}

.pageWrapper {
  padding: 40px 55px;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.helpTooltip {
  margin-top: 45px;
  padding: 15px 10px;
  border-radius: 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  background: #fff2eb;
  a {
    color: #E13434;
    text-decoration: none;
    font-weight: 600;
  }
  span {
    padding-left: 16px;
  }
}