.card {
  flex-grow: 1;
  width: 100%;
  max-width: 550px;
  background: #FFF;
  border-radius: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 90vh;
  overflow-y: auto;

  &Title {
    font-family: Montserrat, sans-serif;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &Footer {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    padding-top: 15px;
    
    a {
      color: #E13434;
    }
  }
  &Link {
    color: #E13434;
    font-size: 14px;
    margin-top: 15px;
    display: block;
  }
}