.card {
  background: #FFF;
  border-radius: 16px;
  padding: 20px 30px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.025);
  
  &Title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  
  &Footer {
    padding-top: 10px;
    border-top: 1px solid #E5E5E5;
    &Item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2px;
      &Label {
        font-weight: 600;
      }
      &Value {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }
  }
}

.button {
  font-family: Montserrat, sans-serif;
  height: 42px;
  background: #008B5D;
  color: #FFF;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  border-radius: 10px;
  transition: all 0.15s ease-out;
  padding: 0 15px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  text-decoration: none;
  span {
    margin-left: 8px;
  }
  &:hover {
    background: darken(#008B5D, 10%);
  }
}

.buttonSquare {
  min-width: 42px;
  min-height: 42px;
  width: 42px;
  height: 42px;
  color: #343434;
  padding: 0;
  
  &:hover {
    background: rgba(52, 52, 52, 0.05);
  }
}

.statusDot {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00D16C;
}

.menuItem {
  min-width: 170px;
  min-height: 42px;
  &Label span {
    font-family: Open Sans, sans-serif !important;
  }
}