.flex {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  a {
    font-weight: 600;
    color: #008B5D;
    border: 1px solid #008B5D;
    text-decoration: none;
    background: #E4EEEB;
    border-radius: 3px;
    padding: 1px 5px;
  }
}

.sectionTitle {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
  a {
    font-weight: 600;
    color: #343434;
    border: 1px solid #343434;
    text-decoration: none;
    background: #ECECEC;
  }
}

.done {
  text-decoration: line-through;
}