
.wrapper {
  z-index: 1000;
}

.flex {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.button {
  background: rgba(0,0,0,0.7);
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  text-transform: none;
  color: #FFF;
  margin-bottom: 10px;
  border-radius: 100px;
  transition: all 0.2s ease-out;
  font-weight: 600;
  height: 40px;
  padding: 0 18px 0 13px;
  line-height: 40px;
  // margin-top: -50px;
  span {
    margin-left: 4px;
  }
  &:hover {
    background: rgba(0,0,0,1);
  }
}