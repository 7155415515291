.title {
  font-size: 36px;
  font-weight: 600;
  height: 52px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 20px;
  // justify-content: space-between;
}

.divider {
  height: 1px;
  background: #E5E5E5;
  margin: 30px 0 20px;
}

.sectionTitle {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.deleteButton {
  margin-top: 15px;
  background: #bf4e44;
  &:hover {
    background: darken(#bf4e44, 10%);
  }
}